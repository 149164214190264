import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import "./PropertyCard.scss"
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Col } from "react-bootstrap"
import { format } from "date-fns"
import { getWhatsAppURL } from "../../common/misc"
import NoImage from "../../images/no-image.png"
import { SaveItem } from "@myaccount/website-service"
import { phoneNumber } from "../common/site/utils"

const PropertyCard = props => {
  const propertyitem = props
  const userObjects = props.userObjects
  // console.log("🚀 ~ file: PropertyCardOffplan.js:14 ~ PropertyCard ~ propertyitem", propertyitem)

  const [icon, setIcon] = useState(false)
  const showIcon = () => {
    setIcon(!icon)
  }

  // useEffect(() => {
  //   const image = document.getElementById(props.propertyDetailsLink+"image") //
  //   const content = document.getElementById(props.propertyDetailsLink+"content") //
  //   image.setAttribute("style",`height:${content?.offsetHeight + 60}px`);
  // }, [])

  // Capitalize
  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  //

  let noMaxPrice = props?.maxPrice?.includes('AED undefined') 
  return (
    <React.Fragment>
      <Col md={12} id={propertyitem.propertyid}>
        <div className="property-card property-card-offplan">
          <div className="properties-wrapper">
            <Link to={props.propertyDetailsLink} className="img-zoom">
              {props.card_type == "similar_property" ? (
                // <GetGGFXImage
                //   imagename={"property.images.featuredProperties"}
                //   imagesource={
                //     propertyitem.propertyImages &&
                //     propertyitem.propertyImages[0]
                //   }
                //   fallbackalt={propertyitem.location}
                //   imagetransformresult={props.processedImages}
                //   id={props.propertyid}
                // />
                <img
                  src={
                    propertyitem.propertyImages &&
                    propertyitem.propertyImages[0] &&
                    propertyitem.propertyImages[0].url
                  }
                  alt={propertyitem.location}
                />
              ) : propertyitem.propertyImages &&
                propertyitem.propertyImages.length == 0 ? (
                <img src={NoImage} alt={propertyitem.location + " - Om Home"} />
              ) : (
                <ShowProcessedImage
                  images={
                    propertyitem.propertyImages &&
                    propertyitem.propertyImages[0]
                  }
                  attr={{
                    className: "mb-0 img-fluid",
                    alt: propertyitem.location + " - Om Home",
                  }}
                  transforms={imageConfig.property.searchResults.sizes}
                  id={props.propertyDetailsLink + "image"}
                />
              )}
            </Link>
          </div>
          <div
            className="about-properties"
            id={props.propertyDetailsLink + "content"}
          >
            <div className="properties-info">
              <div className="properties-heading">
                <Link to={props.propertyDetailsLink}>
                  <h2>{propertyitem.location}</h2>
                </Link>
                <SaveItem type="property" pid={propertyitem.propertyid} userObjects={userObjects}>
                    <a href="javascript:void(0)" className='save-btn'><i className="icon icon-save-black"></i></a>
                </SaveItem>
                {/* <i onClick={showIcon}  className={icon ? "icon-fill" : "icon-like"}></i> */}
              </div>

              <p className="location"><i className="icon icon-offplan-marker"></i>{capitalize(propertyitem.description)}</p>
            </div>

            <div className="bedroom-count-section">
              <p className="bedrooms">{propertyitem.propertyApartmentDesc}</p>
            </div>
            <div className="price-range-section">
              <p className="range">
                {props.price} {noMaxPrice ? `` : `- ${props.maxPrice}`}
              </p>
            </div>
            <div className="developer-completion-section">
              {propertyitem.propertyDeveloper && (
                <>
                  <span className="developer">
                    By {propertyitem.propertyDeveloper}
                  </span>
                </>
              )}
              {propertyitem.propertyCompletionDate && (
                  <>
                  <span>{" / "}</span>
                  <span className="completion">
                  {format(
                    new Date(propertyitem.propertyCompletionDate),
                    "MMM yyyy"
                  )}{" "}
                  Completion
                </span>
                </>
              )}
            </div>

            <div className="cta-section">
              <Link
                to={`/book-a-viewing?pid=${props.propertyid}`}
                href="#"
                className="enquire-section"
              >
                <i className="icon icon-email-bold"></i>
                <span>enquire</span>
              </Link>
              <a href={`tel:${phoneNumber}`} className="phone-section">
                <i className="icon icon-phone-bold"></i>
                <span>{phoneNumber} </span>
              </a>
              <a href={getWhatsAppURL(phoneNumber)} target="_blank">
                <i className="icon icon-whatsapp-bold"></i>
              </a>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default PropertyCard
